import { RefObject, useEffect, useState } from 'react';

export function useActiveIndex(
  ref: RefObject<HTMLDivElement>,
  // additional delta to containerWidth (for example gap between slides).
  delta: number = 0,
) {
  const [ activeIndex, setActiveIndex ] = useState(0);

  useEffect(() => {
    const containerElement = ref?.current as HTMLDivElement;
    const firstElement = containerElement.firstElementChild;

    const update = () => {
      const infoContainerElement = containerElement.getBoundingClientRect();
      const infoFirstElement = firstElement.getBoundingClientRect();
      const containerWidth = infoContainerElement.width;
      const shiftFirstElement = infoFirstElement.left;
      const newActiveIndex = Math.round(
        Math.abs(shiftFirstElement / (containerWidth + delta)),
      );
      setActiveIndex(newActiveIndex);
    };

    update();

    containerElement?.addEventListener('scroll', update, { passive: true });

    return () => {
      // Typescript does not accept passive as an option currently
      // @ts-ignore
      containerElement?.removeEventListener('scroll', update, { passive: true });
    };
  }, []);

  return activeIndex;
}
